/* Dropdown Menu */
var dropItems = document.querySelectorAll(".main-nav > ul > li");
var mainNav = document.querySelector(".main-nav");
var navButtons = document.querySelectorAll(".header .nav-drop");

var wave = document.querySelector(".feature-wave");
//
    var instance = new SV.HoverIntent(dropItems, {
        onEnter: function(targetItem) {
            // called on mouseenter with intent
            targetItem.querySelector(".nav-header").classList.add("active");
            if(!mainNav.hasAttribute("data-spot")) {
                vSlideDown(targetItem.querySelector(".nav-drop"), 250);
                console.log("SLIDE DOWN");
                if (wave != null)
                    wave.classList.add("faded");
            }
            else {
                navButtons.forEach(function(ditem) {
                    ditem.style.display = "none";
                });
                targetItem.querySelector(".nav-drop").style.display = "block";
                console.log("SET TO BLOCK");
            }
            mainNav.setAttribute("data-spot",indexInParent(targetItem));
        },
        onExit: function(targetItem) {
            // call on mouseleave after timeout
            if(indexInParent(targetItem) == mainNav.getAttribute("data-spot")) {
                mainNav.removeAttribute("data-spot");
                targetItem.querySelector(".nav-header").classList.remove("active");
                vSlideUp(targetItem.querySelector(".nav-drop"), 250);
                if (wave != null)
                    wave.classList.remove("faded");
            }
        },
 //   });
/*
    ditem.addEventListener("mouseenter", function (e) {
        mainNav.setAttribute("data-spot",indexInParent(e.target));
        //e.target.parentNode.querySelector(".dropdown-area").style.display = "block";
    }, false);

    ditem.addEventListener("mouseleave", function (e) {
        if(indexInParent(e.target) == mainNav.getAttribute("data-spot")) {
            mainNav.removeAttribute("data-spot");
        }
        //e.target.parentNode.querySelector(".dropdown-area").style.display = "none";
    }, false);
    
*/   

   // menuHover(ditem);
});

function indexInParent(node) {
    var children = node.parentNode.childNodes;
    var num = 0;
    for (var i=0; i<children.length; i++) {
         if (children[i]==node) return num;
         if (children[i].nodeType==1) num++;
    }
    return -1;
}

function menuHover(el) {
    var interval;
    var outInterval;
    var mel = el.querySelector(".dropdown-area");

    el.addEventListener("mouseleave", function (event) {
        
        interval = setInterval(function () {
            mel.style.display = "none";
        }, 500);
        if (outInterval) {
            clearInterval(outInterval);
        }
    });

    el.addEventListener("mouseover", function (event) {
        outInterval = setInterval(function () {
            mel.style.display = "block";
        }, 500);
        if (interval) {
            clearInterval(interval);
        }
    });
}




/*** Video Pops ***/
var dvs = document.querySelectorAll("[data-video]:not([data-video='']");
dvs.forEach(function (item) {
    item.addEventListener("click", function (event) {
        event.preventDefault();
        event.stopPropagation();

        const myVid = document.createElement("div");
        myVid.classList.add("video-modal");
        vidBuild = "<div class='video-modal-content'><button class='video-modal-close'>&times;<span class='visuallyhidden'> close</span></button>";
        if (event.target.getAttribute("data-video").indexOf("vimeo") != -1) {
            var vid = event.target.getAttribute("data-video").replace("https://vimeo.com/", "");
            if (vid.indexOf("/") != -1) {
                vid = vid.substring(0, vid.indexOf("/"));
            }
            vidBuild += "<div style='padding: 56.25% 0 0 0; position: relative; '><iframe src='https://player.vimeo.com/video/" + vid + "?title=0&byline=0&portrait=0' style='position:absolute;top:0;left:0;width:100%;height:100%;' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe></div><script src='https://player.vimeo.com/api/player.js'></script>";
        }
        else if (event.target.getAttribute("data-video").indexOf("youtube") != -1) {
            var vid = event.target.getAttribute("data-video").replace("https://www.youtube.com/watch?v=", "");
            if (vid.indexOf("/") != -1) {
                vid = vid.substring(0, vid.indexOf("/"));
            }
            vidBuild += "<div style='padding: 56.25% 0 0 0; position: relative; '><iframe src='https://www.youtube.com/embed/" + vid + "' style='position:absolute;top:0;left:0;width:100%;height:100%;' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen' allowfullscreen></iframe></div>";
        }
        else if (event.target.getAttribute("data-video").indexOf(".mp4") != -1) {
            vidBuild += "<video width='100%' controls autoplay><source src='" + event.target.getAttribute("data-video") + "' type='video/mp4'></video>";
        }
        vidBuild += "</div>";
        myVid.innerHTML = vidBuild;

        var vm = document.querySelector(".video-modal");
        if (vm) {
            vm.parentNode.removeChild(vm);
        }
        document.body.appendChild(myVid);
    }, false);
});

document.addEventListener("click", function (event) {
    if (event.target.matches(".video-modal")) {
        var vm = document.querySelector(".video-modal");
        if (vm) {
            vm.parentNode.removeChild(vm);
        }
    }
    else if (event.target.matches(".video-modal-content")) {
        event.stopPropagation();
    }
    else if (event.target.matches(".video-modal-close")) {
        event.stopPropagation();
        var vm = document.querySelector(".video-modal");
        if (vm) {
            vm.parentNode.removeChild(vm);
        }
    }
    else if (event.target.matches(".mobile-accordion .info > h2")) {
        event.target.parentNode.parentNode.parentNode.classList.toggle("open");
    }
    else {
        return;
    }
}, false);



/**** ESCAPE KEY ACTIONS ****/

document.addEventListener('keydown', function (e) {
    if (e.key == "Escape") {
        if (document.body.classList.contains("login-open")) {
            document.querySelector(".js-login-close").click();
        }
        if (document.body.classList.contains("search-open")) {
            document.querySelector(".js-search").click();
        }
        if (document.body.classList.contains("menu-open")) {
            document.querySelector(".js-nav-mobile").click();
        }
    }
});





document.addEventListener("click", element => {
    /*
    if (element.target.matches(".drop-content a")) {
        mainNav.removeAttribute("data-spot");
        vSlideUp(document.querySelector(".nav-header.active").querySelector(".nav-drop"), 250);
        document.querySelector(".nav-header.active").classList.remove("active");
        if (wave != null)
            wave.classList.remove("faded");
    }
    */
    if (element.target.matches(".mobile-nav .mobile-nav-header")) {
        var mn = document.querySelector(".mobile-nav-area").classList.add("open");
        event.target.parentNode.classList.add("active");
    }
    if (element.target.matches(".mobile-nav .nav-close")) {
        var mn = document.querySelector(".mobile-nav-area").classList.remove("open");
        var me = event.target;
        setTimeout(function () { me.parentNode.parentNode.classList.remove("active"); }, 500);
    }
}, false);


/*** MAIN NAV ***/
window.addEventListener("hashchange", function (event) {
    var drops = document.querySelectorAll(".nav-drop");

    drops.forEach(function (item) {
        item.style.display = "none";
    });

    if (document.querySelector(location.hash).length > 0) {
        if (document.querySelector(location.hash).classList.contains("js-tab")) {
            var hs = document.querySelector(location.hash).querySelector("button").click();
        }
    }

}, false);


/*** ALERTS ***/
function getCookie(name) {
    let value = `; ${document.cookie}`;
    let parts = value.split(`; ${name}=`);
    if (parts.length === 2) { 
        return parts.pop().split(';').shift();
    }
    else {
        return null;
    }
}
document.addEventListener("click", element => {
    if (element.target.matches(".js-close-alert")) {
        var cook = getCookie("alerts") + "|" + event.target.parentNode.getAttribute("data-alert-id")+"|";
        document.cookie = "alerts=" + cook + "; path=/; max-age=" + (60 * 60 * 24 * 14) + ";";

        vSlideUp(element.target.parentNode);
    }
}, false);



/*** SCRIPT ANIMATIONS ***/
let observer = new IntersectionObserver(function (entries) {
    entries.forEach(function (entry) {
        // Log if the element and if it's in the viewport
        if(entry.target.matches(".brick-split")) {
            
            if(entry.isIntersecting) {
                entry.target.timer = setInterval(function() {
                    entry.target.style.backgroundPosition = (120 - (-20*(entry.target.getBoundingClientRect().top/entry.target.offsetHeight)))+"% 0";
                }, 30);
            }
            else {
                clearInterval(entry.target.timer);
            }
        }
    });
});

/******* COUNTER ON SCROLL **********/
// How long you want the animation to take, in ms
const animationDuration = 2000;
// Calculate how long each ‘frame’ should last if we want to update the animation 60 times per second
const frameDuration = 1000 / 60;
// Use that to calculate how many frames we need to complete the animation
const totalFrames = Math.round( animationDuration / frameDuration );
// An ease-out function that slows the count as it progresses
const easeOutQuad = t => t * ( 2 - t );

// The animation function, which takes an Element
const animateCountUp = el => {
    let frame = 0;
    const countTo = parseInt(el.innerHTML.replace(/\D/g,""), 10 );
    // Start the animation running 60 times per second
    const counter = setInterval( () => {
        frame++;
        // Calculate our progress as a value between 0 and 1
        // Pass that value to our easing function to get our
        // progress on a curve
        const progress = easeOutQuad( frame / totalFrames );
        // Use the progress value to calculate the current count
        const currentCount = Math.round( countTo * progress );

        // If the current count has changed, update the element
        if ( parseInt( el.innerHTML.replace(",",""), 10 ) !== currentCount ) {
            el.innerHTML = currentCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
        }

        // If we’ve reached our last frame, stop the animation
        if ( frame === totalFrames ) {
            clearInterval( counter );
        }
    }, frameDuration );
};




/*** SCROLL ANIMATIONS ***/
let scrollObserver = new IntersectionObserver(function(entries) {
    entries.forEach(function (entry) {
        if(entry.target.matches(".scroll")) {
            if (entry.isIntersecting && !entry.target.classList.contains("active")) {
                entry.target.classList.add("active");
                if (entry.target.matches(".count")) {
                    setTimeout(function () {
                        animateCountUp(entry.target);
                    }, 1000);
                }
            }
        }
    });
},{threshold: 0.3});

/*** STICKY HEADER ***/
// get the sticky element
const stickyElm = document.querySelector('.headers')

const stickyObserver = new IntersectionObserver(function(entries) {
    entries.forEach(function (entry) {
        if (entry.isIntersecting < 1) {
            //Sticky

            entry.target.classList.remove('unSticky');
            entry.target.classList.remove('wasSticky');
            entry.target.classList.add('wasUnSticky');

            if (!entry.target.classList.contains("isSticky")) {
                setTimeout(function () {
                    entry.target.classList.add('isSticky');
                }, 50);
            }
            
            
        }
        else {
            //Non Sticky
            if (entry.target.classList.contains("isSticky")) {
                entry.target.classList.remove('isSticky');
                entry.target.classList.remove('wasUnSticky');
                entry.target.classList.add('wasSticky');
                
                if (!entry.target.classList.contains("unSticky")) {
                    setTimeout(function () {
                        entry.target.classList.add('unSticky');
                    }, 50);
                }
            }
            
        }

    });
},
  {threshold: [1]}
);
stickyObserver.observe(stickyElm);


let scrolls = document.querySelectorAll(".scroll");

scrolls.forEach(function(item) {
    scrollObserver.observe(item);
});


/** STICKY SIDEBAR ***/
var sbObserver = new IntersectionObserver(function(entries) {
    entries.forEach(function (entry) {
        if(entry.boundingClientRect.top < 400) { //if it hits top of viewport
            var sidebarSearch = document.querySelector(".sidebar-search");
            if(entry.intersectionRatio < 1) {
                vSlideDown(sidebarSearch);
            }
            else {
                vSlideUp(sidebarSearch);
            }
        }
    });
},
{rootMargin: "-180px 0px 0px 0px", threshold: [1]});
// The element to observe
let sbs = document.querySelectorAll('.sidebar');
// Attach it to the observer
sbs.forEach(function(item) {
    sbObserver.observe(item);
});



/*** ESCAPE KEY CLOSURES ***/
document.body.addEventListener('keypress', function(e) {
  if (e.key == "Escape") {
    if(document.querySelector(".js-search-area").style.display == "block") {
        document.querySelector(".js-search-close").click();
    }
    if(document.querySelector(".js-login-area").style.display == "block") {
        document.querySelector(".js-login-close").click();
    }
  }
});



var body = document.querySelector("body");

/*** Search Window ***/
document.addEventListener("click", function(e) {

    //Search Open
    if(e.target.matches(".js-search")) {
        var btn = document.querySelector(".js-search-area");

        var img = document.querySelectorAll(".js-search img");
        
        if(!body.classList.contains("search-open"))
        {
            vSlideDown(btn);
            if(img.length > 0) {
                img.forEach(function(mImg) {
                    mImg.setAttribute("src","/Content/images/ico-close.svg");
                });
            }

            body.classList.add("search-open");
            if(body.classList.contains("login-open")) {
                document.querySelector(".js-login-close").click();
            }
            if(document.querySelector(".js-nav-mobile").classList.contains("open")) {
                document.querySelector(".js-nav-mobile").click();
            }
            var searchBox = document.querySelector(".header-search .search-key");
            searchBox.focus();

            if (window.innerWidget < 900) {
                window.scrollTo({ top: 0, behavior: "smooth" });
            }
        }
        else {
            vSlideUp(btn);
            body.classList.remove("search-open");

            var img = document.querySelectorAll(".js-search img");
            if(img.length > 0) {
                img.forEach(function(mImg) {
                    mImg.setAttribute("src","/Content/images/ico-search.svg");
                });
            }

            var img = document.querySelectorAll(".mobile-nav-area .js-search img");
            img.forEach(function (mImg) {
                mImg.setAttribute("src", "/Content/images/ico-nav_search.svg");
            });
        }
        

        
    }
    //Search Close
    else if(e.target.matches(".js-search-close")) {
        var btn = document.querySelector(".js-search-area");
        vSlideUp(btn);
        body.classList.remove("search-open");

        var img = document.querySelectorAll(".js-search img");
        if(img.length > 0) {
            img.forEach(function (mImg) {
                mImg.setAttribute("src", "/Content/images/ico-search.svg");
            });
        }
        var img = document.querySelectorAll(".mobile-nav-area .js-search img");
        img.forEach(function (mImg) {
            mImg.setAttribute("src", "/Content/images/ico-nav_search.svg");
        });
    }
    //Login Open
    else if(e.target.matches(".js-login")) {
        var btn = document.querySelector(".js-login-area");
        if(body.classList.contains("login-open")) {
            document.querySelector(".js-login-close").click();
        }
        else {
            vSlideDown(btn);
            body.classList.add("login-open");

            var loginBox = document.querySelector(".personal-form #pbi-username");
            loginBox.focus();

            if(body.classList.contains("search-open")) {
                document.querySelector(".js-search-close").click();
            }
            if (body.classList.contains("menu-open")) {
                document.querySelector(".js-nav-mobile").click();
            }
        }

        if(e.target.classList.contains("login-mobile")) {
            if(e.target.innerText.trim() == "Login") {
                e.target.innerText = "Close";
                e.target.classList.add("active");
            }
            else {
                e.target.innerText = "Login";
                e.target.classList.remove("active");
            }
        }
    }
    //Login Close
    else if(e.target.matches(".js-login-close")) {
        var btn = document.querySelector(".js-login-area");
        vSlideUp(btn);
        body.classList.remove("login-open");
        document.querySelector('.login-mobile').innerText = 'LOGIN';
        document.querySelector('.login-mobile').classList.remove('active');
    }
    //Personal Login Tab
    else if(e.target.matches(".js-personal-tab")) {
        document.querySelector(".js-business-tab").classList.remove("active");
        document.querySelector(".js-personal-tab").classList.add("active");

        document.querySelector("#mobile-business-form").classList.remove("active");
        document.querySelector("#mobile-personal-form").classList.add("active");
    }
    //Business Login Tab
    else if(e.target.matches(".js-business-tab")) {
        document.querySelector(".js-business-tab").classList.add("active");
        document.querySelector(".js-personal-tab").classList.remove("active");

        document.querySelector("#mobile-business-form").classList.add("active");
        document.querySelector("#mobile-personal-form").classList.remove("active");
    }
    //Mobile Dropdown/Accordion
    else if(e.target.matches(".js-mobile-dropdown-btn")) {
        var content = e.target.parentNode.querySelector(".js-mobile-dropdown-content");
        var dropButton = e.target;
        if(dropButton.classList.contains("open")) {
            dropButton.classList.remove("open");
            vSlideUp(content);
        }
        else {
            dropButton.classList.add("open");
            vSlideDown(content);
        }
    }
    //Login Mobile Dropdown
    else if(e.target.matches(".js-login-drop")) {
        
        var btn = e.target.parentNode.querySelector("ul");
        if(btn.style.display != "block") {
            vSlideDown(btn); //.style.display = "block";
            e.target.parentNode.classList.add("active");
        }
        else {
            vSlideUp(btn); //btn.style.display = "none";
            e.target.parentNode.classList.remove("active");
        }
    }
    //Mobile Nav Open
    else if(e.target.matches(".js-nav-mobile")) {
        var btn = document.querySelector(".js-mobile-nav-area");
        if(e.target.classList.contains("open")) {
            vSlideUp(btn);
            e.target.querySelector(".menu-text").innerText = "MENU";
            e.target.classList.remove("open");
            document.querySelector(".js-mobile-nav-area").classList.remove("open");
            document.querySelector("body").classList.remove("menu-open");
        }
        else {
            vSlideDown(btn);
            e.target.querySelector(".menu-text").innerText = "CLOSE";
            e.target.classList.add("open");
            document.querySelector("body").classList.add("menu-open");
        }
    }
    //Mobile Nav Close
    else if(e.target.matches(".js-nav-mobile-close")) {
        var btn = document.querySelector(".js-mobile-nav-area");
        vSlideUp(btn);
    }
    //Mobile Drop
        /*
    else if(e.target.matches(".mobile-nav > ul > li > button")) {
        var btn = e.target.parentNode.querySelector(".nav-drop");
        if(btn.style.display != "block") {
            vSlideDown(btn); //.style.display = "block";
            e.target.parentNode.classList.add("active");
        }
        else {
            vSlideUp(btn); //btn.style.display = "none";
            e.target.parentNode.classList.remove("active");
        }
    }
    else if(e.target.matches(".mobile-nav .nav-drop .drop-content > ul > li > button")) {
        var btn = e.target.parentNode.querySelector("ul");
        if(btn.style.display != "block") {
            vSlideDown(btn); //.style.display = "block";
            e.target.parentNode.classList.add("active");
        }
        else {
            vSlideUp(btn); //btn.style.display = "none";
            e.target.parentNode.classList.remove("active");
        }
    }
    */
    else if(e.target.matches(".footer-nav > ul > li > a")) {
        if(window.innerWidth < 900) {
            e.preventDefault();
            var btn = e.target.parentNode.querySelector("ul");
            if(btn.style.display != "block") {
                vSlideDown(btn); //.style.display = "block";
                e.target.parentNode.classList.add("active");
            }
            else {
                vSlideUp(btn); //btn.style.display = "none";
                e.target.parentNode.classList.remove("active");
            }
        }
    }
    else if(e.target.matches(".js-accordion-header")) {
        
        var cnt = e.target.parentNode.parentNode.parentNode.querySelector(".js-accordion-content");
        e.target.parentNode.parentNode.parentNode.classList.add("open");
        vSlideDown(cnt);
        
    }
    else if(e.target.matches(".js-accordion-close")) {
        
        var cnt = e.target.parentNode.parentNode.parentNode.querySelector(".js-accordion-content");
        e.target.parentNode.parentNode.parentNode.classList.remove("open");
        vSlideUp(cnt);
        
    }
    else if(e.target.matches(".js-tab-header")) {
        //change tab
        
        var tabs = document.querySelectorAll(".js-tab");
        tabs.forEach(function(tab) {
            if(tab.getAttribute("data-tab") == e.target.getAttribute("data-tab")) {
                tab.classList.add("active");
            }
            else {
                tab.classList.remove("active");
            }
        });
        var headers = document.querySelectorAll(".js-tab-header");
        headers.forEach(function(tab) {
            if(tab.getAttribute("data-tab") == e.target.getAttribute("data-tab")) {
                tab.classList.add("active");
            }
            else {
                tab.classList.remove("active");
            }
        });
        
    }
    else if(e.target.matches(".tab-name")) {
        //change tab
        
        var tab = e.target.parentNode.querySelector(".tab-content");
        
        if(tab.classList.contains("tab-open")) {
            vSlideUp(tab);
            tab.classList.remove("tab-open");
        }
        else {
            vSlideDown(tab);
            tab.classList.add("tab-open");
        }
        
    }
    else if(e.target.matches(".js-branch-selector")) {
        //Show Locations Modal
        
        var modal = document.querySelector(".js-branch-modal");
        
        modal.style.display = "flex"; 
    }
    else if(e.target.matches(".js-branch-modal-close")) {
        //Hide Locations Modal
        
        var modal = document.querySelector(".js-branch-modal");
        
        modal.style.display = "none"; 
    }
    else if(e.target.matches(".js-branch-modal")) {
        //Hide Locations Modal
        if(e.target.id == "locationsModal") {
            var modal = document.querySelector(".js-branch-modal");
            
            modal.style.display = "none"; 
        }
    }
    else if(e.target.matches(".js-location")) {

        //Update for actual locations - save location and id as cookies
        var cook = event.target.innerText;
        var cookID = event.target.getAttribute("data-id");
        document.cookie = "locationID=" + cookID + "; path=/; max-age=" + (60 * 60 * 24 * 14) + ";";
        document.cookie = "locationName=" + cook + "; path=/; max-age=" + (60 * 60 * 24 * 14) + ";";

        window.location.reload();
    }
    else if(e.target.matches(".js-sidebar-btn")) {

        //Blog subscribe box on mobile
        vSlideUp(e.target);
        vSlideDown(e.target.parentNode.querySelector(".sidebar-content"));
    }
    else if(e.target.matches(".js-sidebar-close-btn")) {

        //Blog subscribe box on mobile
        vSlideUp(e.target.parentNode);
        vSlideDown(e.target.parentNode.parentNode.querySelector(".js-sidebar-btn"));
    }
    else if (e.target.matches(".js-alert-bell")) {

        var alerts = document.querySelectorAll(".hidden-alert");
        alerts.forEach(function (alert) {
            vSlideDown(alert);
        });
/*        document.cookie = "alerts=;expires=Thu, 01 Jan 1970 00:00:01 GMT;"*/
        
    }
    else if (e.target.matches(".js-bc-video")) {
        //document.querySelector(".js-bc-video").parentNode.parentNode.parentNode.querySelector("img").click();
        e.target.parentNode.parentNode.parentNode.querySelector(".photo img").click();


    }
    else if (e.target.matches(".jump-lnk")) {
        var btn = document.querySelectorAll("[data-jumplink='" + e.target.innerText.replace(/ /g,'_') + "']");
        if (btn.length > 0) {

            btn[0].scrollIntoView();
        }
        e.target.parentNode.parentNode.querySelector(".js-close-jump").click();
    }
    // Comparison Chart Mobile Previous
    else if (e.target.matches(".js-table-prev")) {
        var base = e.target.parentNode.parentNode; //$(this).parent().parent();
        base.querySelector(".js-table-next").classList.remove("grayed");
        base.setAttribute("data-col", Number(base.getAttribute("data-col")) - 1);
        if (base.getAttribute("data-col") == 1) {
            e.target.classList.add("grayed");
        }
        var spot = base.querySelector("table tr");
        var ths = spot.querySelectorAll("th");
        base.querySelector(".js-rate-head").innerHTML = (eq.call(ths, base.getAttribute("data-col")).innerHTML);
    }
    // Comparison Chart Mobile Next
    else if (e.target.matches(".js-table-next")) {

        var base = e.target.parentNode.parentNode; //$(this).parent().parent();
        base.querySelector(".js-table-prev").classList.remove("grayed");
        base.setAttribute("data-col", Number(base.getAttribute("data-col")) + 1);
        var spot = base.querySelector("table tr");
        var ths = spot.querySelectorAll("th");

        if (base.getAttribute("data-col") == ths.length - 1) {
            e.target.classList.add("grayed");
        }

        base.querySelector(".js-rate-head").innerHTML = (eq.call(ths, base.getAttribute("data-col")).innerHTML);
    }

}, false);






/** masked text **/
var masks = document.querySelectorAll(".f-screen");

masks.forEach(function(mask) {
    mask.setAttribute("data-text",mask.innerText);
});


/** Tabs **/
var tabs = document.querySelectorAll(".tab-area.js-tab");
//Create Tab Nav
var tabHeader = document.createElement("div");
tabHeader.classList.add("tabs-nav");
var cnt = document.createElement("div");
cnt.classList.add("content");
tabs.forEach(function(tab) {
    var rand = Math.floor(Math.random()*10000000000000);
    var btn = document.createElement("button");
    btn.classList.add("js-tab-header");
    btn.setAttribute("data-tab",rand);
    tab.setAttribute("data-tab",rand);
    btn.innerHTML = tab.querySelector(".tab-name").innerHTML;
    tab.querySelector(".tab-name").style.display = "none";
    cnt.appendChild(btn);
});
tabHeader.appendChild(cnt);

if(tabs.length > 0) {
    tabs[0].parentNode.insertBefore(tabHeader,tabs[0]);
}


//Set first as active
var tabBtns = document.querySelectorAll(".js-tab-header");
if(tabBtns.length > 0) {
    tabBtns[0].classList.add("active");
    tabs[0].classList.add("active");
}

//Allow setting of tab via location hash
if (window.location.hash) {
    var hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character

    tabBtns.forEach(function (topic) {
        if (topic.innerText.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "") == hash.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "")) {
            topic.click();
        }
    });
}


(function (document, history, location) {
    var HISTORY_SUPPORT = !!(history && history.pushState);

    var anchorScrolls = {
        ANCHOR_REGEX: /^#[^ ]+$/,
        OFFSET_HEIGHT_PX: 65,

        /**
         * Establish events, and fix initial scroll position if a hash is provided.
         */
        init: function () {
            this.scrollToCurrent();
            window.addEventListener('hashchange', this.scrollToCurrent.bind(this));
            document.body.addEventListener('click', this.delegateAnchors.bind(this));
        },

        /**
         * Return the offset amount to deduct from the normal scroll position.
         * Modify as appropriate to allow for dynamic calculations
         */
        getFixedOffset: function () {

            return this.OFFSET_HEIGHT_PX;
        },

        /**
         * If the provided href is an anchor which resolves to an element on the
         * page, scroll to it.
         * @param  {String} href
         * @return {Boolean} - Was the href an anchor.
         */
        scrollIfAnchor: function (href, pushToHistory) {
            var match, rect, anchorOffset;

            if (!this.ANCHOR_REGEX.test(href)) {
                return false;
            }

            match = document.getElementById(href.slice(1));

            if (match) {
                rect = match.getBoundingClientRect();
                anchorOffset = window.pageYOffset + rect.top - this.getFixedOffset();
                window.scrollTo(
                {
                    top: anchorOffset,
                        left: window.pageXOffset,
                            behavior: 'smooth'
                });

                // Add the state to history as-per normal anchor links
                if (HISTORY_SUPPORT && pushToHistory) {
                    history.pushState({}, document.title, location.pathname + href);
                }
            }

            return !!match;
        },

        /**
         * Attempt to scroll to the current location's hash.
         */
        scrollToCurrent: function () {
            this.scrollIfAnchor(window.location.hash);
        },

        /**
         * If the click event's target was an anchor, fix the scroll position.
         */
        delegateAnchors: function (e) {
            var elem = e.target;

            if (
                elem.nodeName === 'A' &&
                this.scrollIfAnchor(elem.getAttribute('href'), true)
            ) {
                e.preventDefault();
            }
        }
    };

    window.addEventListener(
        'DOMContentLoaded', anchorScrolls.init.bind(anchorScrolls)
    );
})(window.document, window.history, window.location);


/** CAROUSELS **/


document.addEventListener( 'DOMContentLoaded', function() {
    var carousels = document.querySelectorAll(".splide");
    if(carousels.length > 0) {
        var splide = new Splide('.splide', {
            perPage = 6,
            type: 'loop',
            drag: 'free',
            focus: 'center',
            padding: '5rem',
            gap: '30px',
            breakpoints: {
                900: {
                  perPage: 1
                },
            }
        });
        splide.mount();
    }

    var carousels = document.querySelectorAll(".splideFull");
    if (carousels.length > 0 && document.querySelectorAll("html.hydrated").length == 0) {
        var splide = new Splide('.splideFull', {
            perPage: 1,
            type: 'loop',
            drag: false,
            focus: 'center',
            breakpoints: {
                900: {

                    drag: true,
                },
            },
        });
        splide.mount();
    }

    var carousels = document.querySelectorAll(".splideAuto");
    if (carousels.length > 0) {
        const splider = new Splide('.splideAuto', {
            type: 'loop',
            drag: 'free',
            focus: 'center',
            padding: '5rem',
            gap: '30px',
            pagination: false,
            arrows: false,
            autoWidth: true,
            autoScroll: {
                speed: 1,
            },
            breakpoints: {
                900: {
                  padding: '2rem',
                  //perPage: 1,
            autoWidth: false,
                },
            },
        });
        splider.mount(window.splide.Extensions);
    }

    //COOKIE LOCATION
    if(getCookie("locationID") != null) {
        var locs = document.querySelectorAll(".js-current-branch");

        locs.forEach(function(loc) {
            loc.innerText = getCookie("locationName");
            loc.setAttribute("data-id", getCookie("locationID"));
        });
    }


    //Compare Chart Highlight
    var cmp = document.querySelectorAll(".compare-table");

    if(cmp.length > 0) {
        var pageTitle = document.querySelector("h1");
        if(pageTitle != null) {
            cmp.forEach(function(table) {
                var ths = table.querySelectorAll("th");
                ths.forEach(function(th) {
                    if(th.innerText == pageTitle.innerText) {
                        var cellIndex = th.cellIndex;

                        var tds = table.querySelectorAll("th,td");
                        tds.forEach(function(td) {
                            if(td.cellIndex == cellIndex) {
                                td.classList.add("active");
                            }
                        });
                    }
                })
            });
        }
    }
});


/**
 * ADA - Remove id=pasted
 */
var ids = document.querySelectorAll("#isPasted");
ids.forEach(function (item) {
    item.removeAttribute('id');
});

/** Comparison Charts 
 * Add controls on mobile **/
function eq(index) {
    if (index >= 0 && index < this.length)
        return this[index];
    else
        return -1;
}

var charts = document.querySelectorAll(".comparison-chart");

charts.forEach(function (chart) {
    var tab = chart.querySelector("table");
    
    var holder = chart.querySelector(".compare-table");
    var ths = tab.querySelector("tr").querySelectorAll("th");
    if (ths.length > 1) {
        holder.setAttribute("data-col", "1");
        var controls = document.createElement("div");
        controls.classList.add("table-controls");
        controls.innerHTML = "<button class='js-table-prev grayed'></button><span class='f-h2 js-rate-head'>" + eq.call(ths, 1).innerHTML + "</span><button class='js-table-next'></button>";

        holder.insertBefore(controls, holder.firstChild);
    }


});


/** JUMP NAVS ***/
var jumpObserver = new IntersectionObserver(function (entries) {
    entries.forEach(function (entry) {
        if (entry.target.matches(".jump-nav")) {
            if (entry.boundingClientRect.top < 200) //if it hits top of viewport
                entry.target.classList.toggle('sticky', entry.intersectionRatio < 1);
        }
        else if (entry.target.matches("[data-jumplink]")) {

            if (entry.isIntersecting) {
                var jumps = document.querySelectorAll(".js-links .jump-lnk");
                jumps.forEach(function (jp) {
                    if (jp.innerText == entry.target.getAttribute("data-jumplink").replace(/_/g, " ")) {
                        jp.classList.add("active");
                    }
                    else {
                        jp.classList.remove("active");
                    }
                });
            }
        }
    });
},
    { rootMargin: "-94px 0px 0px 0px", threshold: [1] });
// The element to observe
let jumps = document.querySelectorAll('.jump-nav');
// Attach it to the observer
jumps.forEach(function (item) {
    jumpObserver.observe(item);
});

/*
window.addEventListener('load', function(){
    var carousels = document.querySelectorAll(".mobile-carousel");
   // carousels.forEach(function(item) {
        new Glider(document.querySelector(".mobile-carousel"), {
          slidesToShow: 1,
          dots: '#dots',
          draggable: false,
          scrollLock: true,
          arrows: {
            prev: '.glider-prev',
            next: '.glider-next'
          }
        });
   // });
});
*/


/**
 *  RATE TABLE MOBILE SETUP
 */

var rates = document.querySelectorAll(".rate-table table");

rates.forEach(function (rt) {
    var trs = rt.querySelectorAll("tr");
    var headers = [];
    for (var i = 0; i < trs.length; i++) {
        var tds = trs[i].querySelectorAll("td,th");
        for (var j = 0; j < tds.length; j++) {
            if (i == 0) {
                headers.push(tds[j].innerText);
            }
            else {
                tds[j].setAttribute("data-title", headers[j]);
            }
        }
    }
});
